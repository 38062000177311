<template>
  <UiPopup
    :model-value="modelValue"
    size="extraBig"
    class="bg-black-100/50"
    popup-classes="!p-0 [&>div]:p-0 md:w-11/12"
    hidden
    :show-header="false"
  >
    <div class="flex max-h-[740px] text-left">
      <div
        class="styled-scrollbar-near max-h-[calc(100vh-80px)] w-1/2 overflow-y-auto overflow-x-hidden bg-black-10 p-8"
      >
        <div class="overflow-hidden" :style="{ height: propertyHeight + 'px' }">
          <PropertyPublicPage
            id="property-brochure"
            :description="form.brochure_description"
            :property="item"
            :user="authStore.getUser"
            class="pointer-events-none w-[calc(98vw)] rounded-3xl border border-black-100 bg-bg-light"
            :style="{ transform: `scale(${getScale})`, transformOrigin: 'top left' }"
          />
        </div>
      </div>
      <div
        class="styled-scrollbar-near flex h-auto max-h-[calc(100vh-80px)] w-1/2 flex-1 flex-col overflow-y-auto bg-white p-8"
      >
        <h3 class="mb-8">Property brochure</h3>
        <div id="recipient-and-description" class="mb-4">
          <div class="flex flex-col gap-6">
            <div>
              <p class="text-subhead-2 mb-3">Recipient</p>
              <UiInputSelect
                v-model="form.lead_id"
                :items="leads"
                placeholder="Lead's Name"
                name="lead-name"
                show-virtual-scroller
                :loading="retrieving"
                :error="useGetFieldErrors(v$, ['lead_id'])"
                @update:model-value="updateDescription"
              />
            </div>
            <div>
              <p class="text-subhead-2 mb-3">Brochure description</p>
              <UiInputTextarea
                v-model="form.brochure_description"
                name="brochure_description"
                placeholder="Description"
                text-area-class="!min-h-[100px]"
                :limit="false"
                :error="useGetFieldErrors(v$, ['brochure_description'])"
              />
            </div>
          </div>
        </div>
        <div>
          <p class="text-subhead-2 mb-3">Project overview</p>
          <div class="rounded-xl border border-primary-20 bg-black-03 p-4">
            <h4 class="mb-1">{{ item.title.en }}</h4>
            <p class="text-caption-2 mb-3 text-black-70">{{ item.address }}</p>
            <p v-if="item.price.min?.length" class="text-subhead-2 mb-4">
              from {{ aedFormatter.format(Number(item.price.min)) }} - to
              {{ aedFormatter.format(Number(item.price.max)) }} {{ item.price.currency }}
            </p>
            <div class="flex gap-1">
              <PropertyTabsUnitTag dark :label="item.sales_status.en" />
              <PropertyTabsUnitTag
                v-if="item.planned_completion_at"
                dark
                :label="`Planned for ${format(new Date(item.planned_completion_at), 'QQQ yyyy')}`"
              />
              <PropertyTabsUnitTag v-if="convertType" dark :label="convertType" />
            </div>
          </div>
        </div>
        <div class="mt-auto flex justify-end gap-4 pt-6">
          <UiButtonBase id="popup_secondary" type="secondary" @click="emits('update:modelValue', false)">
            Close
          </UiButtonBase>
          <UiButtonBase id="popup_primary" :disabled="loading" @click="confirm">Send brochure</UiButtonBase>
        </div>
      </div>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { useWindowSize } from '@vueuse/core'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useUiStore } from '@/store/ui'
import { useAuthStore } from '~/store/auth'
import type { InputItem, PropertyTypes } from '@/types'

const uiStore = useUiStore()
const authStore = useAuthStore()
const emits = defineEmits(['update:modelValue'])
const { width } = useWindowSize()

const aedFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  notation: 'compact',
  maximumFractionDigits: 3,
})

type Props = {
  modelValue: boolean
  item: PropertyTypes.PropertyDetailed
}

const props = defineProps<Props>()

const retrieving = ref(false)
const loading = ref(false)
const leads = ref<InputItem[]>([])
const propertyHeight = ref(0)
const offerHash = ref()

const form = ref({
  lead_id: null,
  project_id: Number(props.item.complex_id),
  user_id: authStore.getUser.id,
  brochure_description: `Hi ____,

In this brochure, you'll find information about the property that matches your initial request and interests. I look forward to hearing your feedback and I'm happy to answer any questions you may have.

Best regards!`,
  image: props.item.album[0],
  project_name: props.item.title?.en,
})

const rules = computed(() => ({
  lead_id: { required: helpers.withMessage('The lead is required', required) },
  brochure_description: { required: helpers.withMessage('The description is required', required) },
}))

const v$ = useVuelidate(rules, form)

const getScale = computed(() => {
  let scale
  if (width.value > 1536) {
    scale = 0.41
  } else if (width.value > 1280) {
    scale = 0.4
  } else {
    scale = 0.38
  }

  return scale
})

onMounted(() => {
  nextTick(() => {
    propertyHeight.value = document.getElementById('property-brochure')?.offsetHeight * getScale.value! + 2 || 0
  })
})

const convertType = computed(() => {
  return props.item.type
    ?.split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
})

onNuxtReady(async () => {
  retrieving.value = true
  try {
    leads.value = await useGetActiveLeadsItems()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    retrieving.value = false
  }
})

const getChosenLead = () => {
  if (!form.value.lead_id) return

  return leads.value.find((l: InputItem) => l.value === form.value.lead_id)
}

const updateDescription = () => {
  if (form.value.brochure_description.includes('____')) {
    const lead = getChosenLead()

    if (!lead?.text) return

    form.value.brochure_description = form.value.brochure_description.replace('____', lead.text)
  }
}

const confirm = async () => {
  if (!(await v$.value.$validate())) return

  loading.value = true
  try {
    const data = await useCreatePropertyOffer(form.value)
    offerHash.value = data.hash
    uiStore.showSnackBanner(
      `You have created offer for lead. 
        Copy link for brochure or open it through lead page.`,
      'success',
      goToLead,
      'Open in lead page',
      copyLink,
      'Copy link'
    )
    emits('update:modelValue', false)
  } catch (error) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}

const goToLead = () => {
  navigateTo(`/leads/${form.value.lead_id}#brochures`, {
    open: {
      target: '_blank',
    },
  })
}

const copyLink = async () => {
  const config = useRuntimeConfig()
  const domain = config.public.APP_URL
  try {
    const link = `${domain}property/${offerHash.value}`
    await navigator.clipboard.writeText(link)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}
</script>

<style scoped lang="scss"></style>
